<template>
    <ScheduleFollowupDialog
        ref="scheduleFollowupDialog"
        class="text-base"
        :initial-date="initialDate"
        :event-description="inviteDescription"
        :custom-schedule="handleScheduleInDialog" />
    <ChatAgentToolFrame is-actionable :is-complete="action.action_state.submitted">
        <div class="chat-1 leading-[normal] flex flex-col gap-1">
            <div class="chat-1 text-[#555BA2]">
                <template v-if="action.action_state.submitted"> Ok great! See you next {{ formattedDate.weekday }} at {{ formattedDate.time }}. </template>
                <template v-else> Our next meeting </template>
            </div>
            <div class="text-[#8C8C8C]">
                <template v-if="action.action_state.submitted"> I have sent an invite to your calendar for next {{ formattedDate.weekday }}. </template>
                <template v-else> Does this time look right to you? </template>
            </div>
        </div>
        <ScheduleTrigger
            v-if="!action.action_state.submitted"
            :disabled="loading"
            class="text-base"
            :date="formattedDate"
            :scheduled="isScheduled"
            @click="scheduleFollowupDialog.open()" />
        <div v-if="hasError" class="body-1 text-red-700">I'm having issues scheduling our meeting. Please try again later.</div>
        <BaseButton v-if="!action.action_state.submitted" :disabled="loading" class="flex justify-center items-center gap-1" theme="primary" @click="handleSchedule"
            ><LoadingSpinner v-if="loading" class="h-4" />Schedule</BaseButton
        >
    </ChatAgentToolFrame>
</template>

<script setup>
import BaseButton from "~vue/components/BaseButton.vue";
import ScheduleFollowupDialog from "~vue/components/ScheduleFollowupDialog.vue";
import ScheduleTrigger from "~vue/components/ScheduleTrigger.vue";
import { useDeferredLoading } from "~vue/composables/useDeferredLoading";
import { useScheduleFollowup } from "~vue/composables/useScheduleFollowup.js";
import LoadingSpinner from "~vue/icons/LoadingSpinner.vue";
import { logError } from "~vue/utils/logUtils";
import { ref, useTemplateRef } from "vue";

import ChatAgentToolFrame from "./ChatAgentToolFrame.vue";

const { loading, start: startLoading, stop: stopLoading } = useDeferredLoading();

const props = defineProps({
    action: {
        type: Object,
        required: true,
    },
    lineIdx: { type: Number, required: true },
    messageId: { type: Number, required: true },
});

const { initialDate, formattedDate, scheduleFollowup, isScheduled, inviteDescription } = useScheduleFollowup({
    line: props.action,
    messageId: props.messageId,
    lineIdx: props.lineIdx,
});

const hasError = ref(false);
const scheduleFollowupDialog = useTemplateRef("scheduleFollowupDialog");

function handleScheduleInDialog() {
    hasError.value = false;
    return scheduleFollowup({ sync: true });
}

async function handleSchedule() {
    startLoading();
    hasError.value = false;

    try {
        await scheduleFollowup({ sync: true });
    } catch (e) {
        logError(e);
        hasError.value = true;
    } finally {
        stopLoading();
    }
}
</script>
