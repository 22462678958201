<template>
    <h3 class="font-bold mb-4">Context</h3>
    <div class="flex flex-col gap-2">
        <div v-for="{ name, is_public } of teamMembers" :key="name" class="flex justify-between items-center">
            <div class="flex items-center gap-4 min-h-8 font-semibold tracking-tight">
                <span class="rounded-full text-[#555BA2] text-center leading-6 bg-[#E0E9FF] w-6 h-6"> {{ getFirstLastInitials(name) }}</span>
                <span class="grow text-[18px]">{{ name }}</span>
            </div>
            <i v-if="is_public" class="bi bi-person-badge text-2xl text-[#8C8C8C]"></i>
        </div>
    </div>
</template>

<script setup>
import { logUserInteraction } from "~vue/utils/logUtils";
import { getFirstLastInitials } from "~vue/utils/stringUtils";
import { inject, onMounted } from "vue";

const coachingSessionId = inject("coachingSessionId");
const userDetails = inject("user_details");

const teamMembers = userDetails.team_members;

onMounted(() => logUserInteraction("team_member_context_shown", {}, coachingSessionId));
</script>
