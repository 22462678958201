<template>
    <div class="flex flex-col gap-6 pt-3 px-6 relative rounded-lg container">
        <h3 class="text-xl font-semibold tracking-tighter">{{ insightTitle ?? firstSentence }}</h3>
        <BaseCollapsible v-model:open="isOpen" class="flex flex-col-reverse gap-3 pb-3">
            <template #trigger>
                <p class="text-[#555BA2] font-semibold">
                    {{ isOpen ? "See less" : "See more" }}
                </p>
            </template>
            <template #content>
                <MarkdownText class="text-base text-[#8C8C8C] font-semibold tracking-tight" :text="insightTitle ? insight : restOfInsight" />
                <div class="text-left text-sm font-medium tracking-tight py-3">
                    <button v-if="!showSavedSuccess" type="button" class="text-[#8C8C8C] bi bi-bookmark" @click="saveInsight">&nbsp;Save this as a topic to revisit</button>
                    <span v-else>
                        <i class="text-[#555BA2] bi bi-bookmark-fill icon-bold"></i>
                        &nbsp;Topic saved!
                    </span>
                </div>
            </template>
        </BaseCollapsible>
    </div>
</template>

<script setup>
/**
 * Serves double duty for displaying two similar widgets, the values insight widget and the internal thinking widget.
 * Both allow saving the insight to a conversation topic for later.
 */
import BaseCollapsible from "~vue/components/BaseCollapsible.vue";
import MarkdownText from "~vue/MarkdownText.vue";
import { logUserInteraction } from "~vue/utils/logUtils";
import { computed, inject, ref } from "vue";

const { emitter } = inject("globalProperties");
const coachingSessionId = inject("coachingSessionId");

const props = defineProps({
    data: { type: Object, default: () => ({}) },
    role: { type: String, default: "user" },
    messageId: { type: Number, default: 0 },
    lineIdx: { type: Number, default: 0 },
    message: { type: Object, default: () => ({}) },
    action_type: { type: String, default: "" },
});

const isOpen = ref(false);
const showSavedSuccess = ref(false);

const insightTitle = computed(() => {
    return props.data.action_params.short_title;
});

const insight = computed(() => props.data.action_params.final_response);

const firstSentence = computed(() => {
    return insight.value.split(". ")[0] + ".";
});

const restOfInsight = computed(() => {
    return insight.value.split(". ").slice(1).join(". ");
});

function saveInsight() {
    emitter.emit(props.action_type, {
        message_id: props.messageId,
        lineIdx: props.lineIdx,
        insight: insight.value,
        coaching_session_id: coachingSessionId,
    });
    logUserInteraction(`${props.action_type}_save_insight`, {}, coachingSessionId);
    showSavedSuccess.value = true;
}
</script>

<style scoped>
.container {
    background: linear-gradient(125deg, rgba(255, 40, 145, 0.04) -22.98%, rgba(0, 63, 220, 0.04) 113.56%);
}

.container::before {
    content: "";
    @apply rounded-lg absolute inset-0 border-2 border-transparent pointer-events-none;
    background: linear-gradient(var(--rotate), #ff238f 0%, #555ba2 100%) border-box;
    mask:
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
    mask-composite: exclude;
}
</style>
