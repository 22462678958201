<template>
    <BaseTooltip>
        <template #trigger>
            <button
                type="button"
                class="group transition-colors flex items-center gap-2 py-3 md:py-2 px-3 bg-white hover:bg-[#F9F9F9] rounded-3xl border-2 border-[#E8E8E8]"
                @click="handleClick">
                <Bell class="h-6 w-auto" foreground-class="fill-[#555BA2] group-hover:fill-[#4B508F]" />
                <span
                    v-if="visibleWidgetCount > 0"
                    class="inline-flex justify-center items-center bg-[rgba(85,91,162,0.12)] text-[#555BA2] group-hover:text-[#4B508F] text-base leading-normal font-semibold tracking-[-0.64px] md:w-8 md:h-8 w-6 h-6 rounded-full">
                    {{ visibleWidgetCount }}
                </span>
            </button>
        </template>
        <template #content>
            {{ isOpen ? "Close Notes" : "Open Notes" }}
        </template>
    </BaseTooltip>
</template>

<script setup>
import BaseTooltip from "~vue/components/BaseTooltip.vue";
import Bell from "~vue/icons/Bell.vue";

defineProps({
    isOpen: {
        type: Boolean,
        required: true,
        default: true,
    },
    visibleWidgetCount: {
        type: Number,
        default: 0,
    },
});

const emit = defineEmits(["click"]);

function handleClick() {
    emit("click");
}
</script>
