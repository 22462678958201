<template>
    <div class="flex flex-col gap-6 items-center max-h-[600px] overflow-auto">
        <div class="w-12 h-12 md:w-16 md:h-16 hidden md:block">
            <CoachingModeMarble />
        </div>
        <h1 class="font-semibold text-2xl tracking-tighter hidden md:inline">Here's a Recap</h1>
        <InsightWidget
            v-for="insight in insights"
            :key="`${insight.chat_message_id}-${insight.lineIdx}`"
            class="w-full md:max-w-80"
            :message="insight.message"
            :data="insight.line"
            :message-id="insight.message.chat_message_id"
            :line-idx="insight.lineIdx"
            :role="insight.message.role"
            :action-type="insight.line.action_name" />
        <BaseAccordion v-if="profileInferences.length > 0" :accordion-ids="[accordionId]" class="accordion w-full">
            <template #expandIcon><span class="shrink-0 font-semibold text-[#555BA2]">View</span></template>
            <template #collapseIcon><span class="shrink-0 font-semibold text-[#555BA2]">Close</span></template>
            <template #[makeTriggerSlot(accordionId)]>
                <div class="flex items-center">
                    <span class="min-w-10 min-h-10 rounded-full border-2 border-[#E8E8E8] grid place-items-center">
                        <Cloud width="20" height="20" />
                    </span>
                    <span
                        v-if="userName"
                        class="rounded-full text-[#555BA2] text-center font-semibold leading-6 bg-[#E0E9FF] min-w-10 min-h-10 grid place-items-center relative -left-2">
                        {{ getFirstLastInitials(userName) }}</span
                    >
                    <span class="font-semibold text-left leading-[normal]">{{ profileInferences.length }} Memories saved to profile</span>
                </div>
            </template>
            <template #[makeContentSlot(accordionId)]>
                <div class="p-4 overflow-y-auto max-h-60">
                    <ChatActionProfileInferences :inferences="profileInferences" hide-forget-button />
                </div>
            </template>
        </BaseAccordion>
    </div>
</template>

<script setup>
import ChatActionProfileInferences from "~vue/ChatActionProfileInferences.vue";
import { makeContentSlot, makeTriggerSlot } from "~vue/ChatWidgets/ChatWidgetAccordion.vue";
import InsightWidget from "~vue/ChatWidgets/InsightWidget.vue";
import BaseAccordion from "~vue/components/BaseAccordion.vue";
import CoachingModeMarble from "~vue/components/navigation/CoachingModeMarble.vue";
import Cloud from "~vue/icons/Cloud.vue";
import { getFirstLastInitials } from "~vue/utils/stringUtils";

defineProps({
    insights: { type: Array, default: () => [] },
    profileInferences: { type: Array, default: () => [] },
    userName: { type: String, default: undefined },
});

const accordionId = "memories";
</script>

<style scoped>
.accordion {
    @apply rounded-badge border-2 border-[#E8E8E8];

    :deep() {
        & .trigger {
            @apply md:min-w-[360px] px-4 rounded-badge;

            &[data-state="open"] {
                @apply rounded-bl-none rounded-br-none;
            }
        }
        & .item {
            @apply rounded-badge;
        }
    }
}
</style>
