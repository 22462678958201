import { ref } from "vue";

/*
 * Composable to manage a loading state that is withheld if resolved
 * within a given threshold period. This can be used to avoid showing
 * loading indicators if the interaction is fast enough.
 */
export function useDeferredLoading({ threshold = 200 } = {}) {
    const loadingTimeout = ref(null);
    const loading = ref(false);

    function start() {
        loadingTimeout.value = window.setTimeout(() => {
            loading.value = true;
        }, threshold);
    }

    function stop() {
        if (loadingTimeout.value) {
            clearTimeout(loadingTimeout.value);
            loadingTimeout.value = null;
        }

        if (loading.value) {
            loading.value = false;
        }
    }

    return {
        loading,
        start,
        stop,
    };
}
