<template>
    <ChatWidgetSection title="About you">
        <template v-if="!hasAboutYouWidgets" #description>Key things I learn about you will be listed here.</template>
        <template #content>
            <ChatWidgetAccordion v-model="aboutYouAccordionExpandedItems" :accordion-ids="aboutYouAccordionIds">
                <template #[makeTriggerSlot(ACCORDION_ID.INSIGHTS)]>
                    <ChatWidgetTitle :show-status="insights.length > 0">
                        <template #icon>
                            <Thunder class="h-6 w-auto" foreground-class="stroke-[#262626]" />
                        </template>
                        <template #text>
                            <span v-if="insights.length > 0">{{ insights.length }}</span> Insight<template v-if="insights.length !== 1">s</template>
                        </template>
                    </ChatWidgetTitle>
                </template>
                <template #[makeContentSlot(ACCORDION_ID.INSIGHTS)]>
                    <div>
                        <InsightWidget
                            v-for="insight in insights"
                            :key="`${insight.chat_message_id}-${insight.lineIdx}`"
                            :message="insight.message"
                            :data="insight.line"
                            :message-id="insight.message.chat_message_id"
                            :line-idx="insight.lineIdx"
                            :role="insight.message.role"
                            :action-type="insight.line.action_name" />
                    </div>
                </template>
                <template #[makeTriggerSlot(ACCORDION_ID.MEMORIES)]>
                    <ChatWidgetTitle :show-status="inferences.length > 0">
                        <template #icon>
                            <Cloud class="h-[20px] w-auto" foreground-class="fill-[#262626] stroke-[#262626]" />
                        </template>
                        <template #text>
                            <span v-if="inferences.length > 0">{{ inferences.length }}&nbsp;</span>
                            <template v-if="inferences.length === 1">Memory</template>
                            <template v-else>Memories</template>
                        </template>
                    </ChatWidgetTitle>
                </template>
                <template #[makeContentSlot(ACCORDION_ID.MEMORIES)]>
                    <div class="pl-4">
                        <ChatActionProfileInferences v-if="inferences.length > 0" :inferences="inferences" @forget-inference="forgetInference" />
                    </div>
                </template>
                <template #[makeTriggerSlot(ACCORDION_ID.CUSTOMIZABLE_TEXT)]>
                    <ChatWidgetTitle :show-status="!!customizableText">
                        <template #icon>
                            <i class="bi bi-journal-text text-2xl" />
                        </template>
                        <template #text> {{ customizableText.line.action_params.title }} </template>
                    </ChatWidgetTitle>
                </template>
                <template #[makeContentSlot(ACCORDION_ID.CUSTOMIZABLE_TEXT)]>
                    <div class="pl-4">
                        <ChatActionCustomizableText :data="customizableText.line" :message-id="customizableText.message.id" :line-idx="customizableText.lineIdx" />
                    </div>
                </template>
                <template #[makeTriggerSlot(ACCORDION_ID.YOUR_TEAM)]>
                    <ChatWidgetTitle :show-status="inferences.length > 0">
                        <template #icon>
                            <i class="bi bi-people text-2xl" />
                        </template>
                        <template #text> Your Team </template>
                    </ChatWidgetTitle>
                </template>
                <template #[makeContentSlot(ACCORDION_ID.YOUR_TEAM)]>
                    <ChatTeamMemberContext />
                </template>
            </ChatWidgetAccordion>
        </template>
    </ChatWidgetSection>
    <HorizontalDivider class="border-2 !my-0" />
    <ChatWidgetSection title="Next steps">
        <template v-if="!hasNextStepsWidgets" #description>I will create some next steps here to keep you on track.</template>
        <template #content>
            <ChatWidgetAccordion v-model="nextStepsAccordionExpandedItems" :accordion-ids="nextStepsAccordionIds">
                <template #[makeTriggerSlot(ACCORDION_ID.ACTION_ITEMS)]>
                    <ChatWidgetTitle :show-status="actionItems.length > 0">
                        <template #icon>
                            <div class="h-8 w-8">
                                <ProgressCircle v-bind="actionItemsProgress" />
                            </div>
                        </template>
                        <template #text>Action Items</template>
                    </ChatWidgetTitle>
                </template>
                <template v-if="actionItems.length > 0" #[makeContentSlot(ACCORDION_ID.ACTION_ITEMS)]>
                    <div
                        :ref="
                            (element) =>
                                registerFocusableAction({
                                    messageId: actionItems[0].message.chat_message_id,
                                    actionName: actionItems[0].line.action_name,
                                    lineIdx: actionItems[0].lineIdx,
                                    onFocus: () => focusWidget(element, nextStepsAccordionExpandedItems, ACCORDION_ID.ACTION_ITEMS),
                                })
                        "
                        class="pl-4 flex flex-col gap-4">
                        <ChatActionActionItems
                            v-for="actionItem in actionItems"
                            :key="`${actionItem.message.chat_message_id}-${actionItem.lineIdx}`"
                            :data="actionItem.line"
                            :message-id="actionItem.message.chat_message_id"
                            :line-idx="actionItem.lineIdx" />
                    </div>
                </template>

                <template #[makeTriggerSlot(ACCORDION_ID.FOLLOW_UP)]>
                    <ChatWidgetTitle :show-status="true" :status-color="followUpWidgetDetails.statusColor">
                        <template #icon>
                            <Calendar class="h-[20px] w-auto stroke-[#262626]" />
                        </template>
                        <template #text
                            ><span :class="followUpWidgetDetails.textColor">{{ followUpWidgetDetails.text }}</span></template
                        >
                    </ChatWidgetTitle>
                </template>

                <template v-if="!!followUp" #[makeContentSlot(ACCORDION_ID.FOLLOW_UP)]>
                    <ChatActionFollowupInSidebar :data="followUp.line" :message-id="followUp.message.chat_message_id" :line-idx="followUp.lineIdx" />
                    <div v-if="!!followUpWidgetDetails.subtext" class="mt-3 text-base font-semibold tracking-[-0.64px] text-[#8C8C8C]">{{ followUpWidgetDetails.subtext }}</div>
                </template>
            </ChatWidgetAccordion>
        </template>
    </ChatWidgetSection>
</template>

<script setup>
import { getCookie } from "/js/utils";
import ChatActionActionItems from "~vue/ChatActionActionItems.vue";
import ChatActionCustomizableText from "~vue/ChatActionCustomizableText.vue";
import ChatActionFollowupInSidebar from "~vue/ChatActionFollowupInSidebar.vue";
import ChatActionProfileInferences from "~vue/ChatActionProfileInferences.vue";
import { ACTION, pluckAction, pluckMultipleActions } from "~vue/chatActions";
import ChatWidgetSection from "~vue/ChatWidgets/ChatWidgetSection.vue";
import HorizontalDivider from "~vue/components/HorizontalDivider.vue";
import ProgressCircle from "~vue/components/ProgressCircle.vue";
import { CHAT_EVENT } from "~vue/events";
import Calendar from "~vue/icons/Calendar.vue";
import Cloud from "~vue/icons/Cloud.vue";
import Thunder from "~vue/icons/Thunder.vue";
import { useChatStore } from "~vue/stores/chatStore";
import { logError } from "~vue/utils/logUtils";
import { DateTime } from "luxon";
import { computed, inject, nextTick, onMounted, onUnmounted, ref, watch } from "vue";

import ChatTeamMemberContext from "./ChatTeamMemberContext.vue";
import ChatWidgetAccordion, { makeContentSlot, makeTriggerSlot } from "./ChatWidgetAccordion.vue";
import ChatWidgetTitle from "./ChatWidgetTitle.vue";
import InsightWidget from "./InsightWidget.vue";

const ACCORDION_ID = {
    MEMORIES: "memories",
    INSIGHTS: "insights",
    ACTION_ITEMS: "action_items",
    YOUR_TEAM: "your-team",
    FOLLOW_UP: "follow_up",
    CUSTOMIZABLE_TEXT: "customizable-text",
};

const { messages } = defineProps({
    messages: { type: Array, required: true },
    registerFocusableAction: { type: Function, default: () => () => {} },
});

const { emitter } = inject("globalProperties");
const coachingSessionId = inject("coachingSessionId");
const userDetails = inject("user_details");

const inferences = ref([]);

onMounted(() => {
    emitter.on(CHAT_EVENT.INFERRED_PROFILE_ANSWER, getProfileInferences);
    emitter.on(CHAT_EVENT.INFERENCE_MADE, getProfileInferences);
    getProfileInferences();
});

onUnmounted(() => {
    emitter.off(CHAT_EVENT.INFERRED_PROFILE_ANSWER, getProfileInferences);
    emitter.off(CHAT_EVENT.INFERENCE_MADE, getProfileInferences);
});

const nextStepsAccordionExpandedItems = ref([ACCORDION_ID.FOLLOW_UP]);
const aboutYouAccordionExpandedItems = ref([ACCORDION_ID.INSIGHTS]);

async function forgetInference({ id: inferenceId }) {
    try {
        const response = await fetch(`/accounts/profile-data/${inferenceId}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": getCookie("csrftoken"),
            },
        });

        if (response.status === 204) {
            inferences.value = inferences.value.filter((item) => item.id !== inferenceId);
        } else {
            throw new Error("Failed to delete inference");
        }
    } catch (e) {
        logError(e);
    }
}

async function getProfileInferences() {
    try {
        const response = await fetch(`/accounts/profile-data/cs/${coachingSessionId}/`, {
            method: "GET",
        });
        const responseData = await response.json();
        inferences.value = responseData;
    } catch (e) {
        logError(e);
    }
}

const insights = computed(() => pluckMultipleActions(messages, [ACTION.INTERNAL_THINKING, ACTION.VALUES_INSIGHT]));

const actionItems = computed(() => pluckMultipleActions(messages, ACTION.ACTION_ITEMS));

const followUp = computed(() => pluckAction(messages, ACTION.FOLLOW_UP));

const customizableText = computed(() => pluckAction(messages, ACTION.CUSTOMIZABLE_TEXT));

watch(
    [insights, inferences, actionItems, followUp, customizableText],
    () => {
        if (insights.value.length > 0) {
            openAccordionItem(aboutYouAccordionExpandedItems.value, ACCORDION_ID.INSIGHTS);
        }
        if (inferences.value.length > 0) {
            openAccordionItem(aboutYouAccordionExpandedItems.value, ACCORDION_ID.MEMORIES);
        }
        if (actionItems.value.length > 0) {
            openAccordionItem(nextStepsAccordionExpandedItems.value, ACCORDION_ID.ACTION_ITEMS);
        }
        if (followUp.value) {
            openAccordionItem(nextStepsAccordionExpandedItems.value, ACCORDION_ID.FOLLOW_UP);
        }
        if (customizableText.value) {
            openAccordionItem(aboutYouAccordionExpandedItems.value, ACCORDION_ID.CUSTOMIZABLE_TEXT);
        }
    },
    { immediate: true },
);

const followUpWidgetDetails = computed(() => {
    if (!followUp.value || !followUp.value.line.action_state.submitted) {
        return {
            text: "Schedule next session",
            textColor: "",
            statusColor: undefined,
            subtext: null,
        };
    }

    let subtext = null;

    const { event_at_guessed, event_at_confirmed } = followUp.value.line.action_params;

    if (!!event_at_confirmed || !!event_at_guessed) {
        const followUpDate = new Date(event_at_confirmed || event_at_guessed);

        // Only show the subtext if the follow-up has not elapsed yet
        if (followUpDate > new Date(Date.now())) {
            const luxonDate = DateTime.fromJSDate(followUpDate);
            const day = luxonDate.toFormat("EEEE");
            const time = luxonDate.toLocaleString(DateTime.TIME_SIMPLE);
            subtext = `Ok great! See you next ${day} at ${time}. Invite sent to your calendar.`;
        }
    }

    return {
        text: "Session Scheduled",
        textColor: "text-[#8C8C8C]",
        statusColor: "bg-[#555BA2]",
        subtext,
    };
});

const actionItemsProgress = computed(() => {
    if (actionItems.value?.length > 0) {
        // Collect all action items from all entries
        let totalItems = 0;
        let totalChecked = 0;

        // Iterate through all action item entries
        actionItems.value.forEach((actionItem) => {
            const { action_items: items } = actionItem.line.action_params;
            if (items && items.length > 0) {
                totalItems += items.length;
                totalChecked += items.filter(({ checked }) => checked).length;
            }
        });

        if (totalItems > 0) {
            const progress = totalChecked === 0 ? 0 : Math.floor((totalChecked / totalItems) * 100);

            return {
                progress,
                content: `${totalChecked}/${totalItems}`,
            };
        }
    }

    return { progress: 0, content: "0/0", gradientFrom: "#D9D9D9", gradientTo: "#D9D9D9" };
});

const hasAboutYouWidgets = computed(() => {
    if (insights.value.length > 0) {
        return true;
    }

    return inferences.value.length > 0;
});

const nextStepsAccordionIds = computed(() => {
    /*
     * "Action Items" is always shown, whereas follow-up
     * is only shown if the follow-up action exists.
     */
    const ids = [ACCORDION_ID.ACTION_ITEMS];

    if (!!followUp.value) {
        ids.push(ACCORDION_ID.FOLLOW_UP);
    }

    return ids;
});

const aboutYouAccordionIds = computed(() => {
    /*
     * "Insights" is always shown, whereas "Memories"
     * is only shown if at least one inference exists.
     */
    const ids = [ACCORDION_ID.INSIGHTS];

    if (inferences.value.length > 0) {
        ids.push(ACCORDION_ID.MEMORIES);
    }

    if (userDetails.is_valencer && useChatStore.hasTeamMemberContext) {
        ids.push(ACCORDION_ID.YOUR_TEAM);
    }

    if (customizableText.value) {
        ids.push(ACCORDION_ID.CUSTOMIZABLE_TEXT);
    }

    return ids;
});

const hasNextStepsWidgets = computed(() => !!followUp.value || actionItems.value.length > 0);

function openAccordionItem(accordion, itemId) {
    if (!accordion.includes(itemId)) {
        accordion.push(itemId);
    }
}

function focusWidget(element, accordion, itemId) {
    openAccordionItem(accordion, itemId);
    /*
     * We use nextTick so that the effect of `openAccordionItem` is
     * settled into the DOM before we interact with it. The timeout
     * is to add a small delay in between opening an accordion and
     * scrolling to the child element.
     */
    nextTick(() => {
        setTimeout(() => element.scrollIntoView({ behavior: "smooth" }), 200);
    });
}
</script>

<style scoped></style>
