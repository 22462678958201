<template>
    <ChatAgentToolFrame is-actionable :is-complete="action.action_state.submitted">
        <div class="chat-1 flex flex-col gap-1">
            <div class="chat-1 text-[#555BA2]">
                <template v-if="action.action_state.submitted"> Ok great! Email summary sent.</template>
                <template v-else>
                    <div class="flex items-center justify-between">
                        Here's a recap
                        <BaseTooltip>
                            <template #trigger>
                                <button :disabled="loading" type="button" class="text-[#8C8C8C] text-lg" @click="toggleEdit">
                                    <i :class="[editing ? 'bi-check-lg' : 'bi-pencil']" class="bi" />
                                </button>
                            </template>
                            <template #content>{{ editing ? "Close" : "Edit" }}</template>
                        </BaseTooltip>
                    </div>
                </template>
            </div>
            <div class="text-[#8C8C8C]">
                <template v-if="action.action_state.submitted">Is there anything else I can help with for today or would you like to wrap up?</template>
            </div>
        </div>
        <template v-if="action.action_state.submitted">
            <BaseButton theme="primary" @click="handleWrapUp">Wrap up</BaseButton>
        </template>
        <template v-else>
            <template v-if="editing">
                <BaseTextarea ref="baseTextareaRef" v-model:input="content" :disabled="loading" class="w-full caret-[#555BA2] leading-[normal] text-[#262626]" />
            </template>
            <template v-else>
                <div class="body-1 text-[#8C8C8C] whitespace-pre-line">{{ renderedSummary }}</div>
                <BaseButton v-if="needsTruncating" theme="secondary" @click="expanded = !expanded">{{ expanded ? "See less" : "See more" }}</BaseButton>
            </template>
            <div v-if="hasError" class="text-red-700 body-1">I'm having issues sending you the email. Please try again later.</div>
            <BaseButton :disabled="loading" theme="primary" class="flex justify-center items-center gap-1" @click="handleSubmit">
                <LoadingSpinner v-if="loading" class="h-4" />
                <i v-else class="bi bi-send"></i>
                Email summary
            </BaseButton>
        </template>
    </ChatAgentToolFrame>
</template>

<script setup>
import { ACTION } from "~vue/chatActions";
import BaseButton from "~vue/components/BaseButton.vue";
import BaseTooltip from "~vue/components/BaseTooltip.vue";
import BaseTextarea from "~vue/components/form/BaseTextarea.vue";
import { useActionHandler } from "~vue/composables/useActionHandler";
import { useDeferredLoading } from "~vue/composables/useDeferredLoading";
import { CHAT_EVENT } from "~vue/events";
import LoadingSpinner from "~vue/icons/LoadingSpinner.vue";
import { logError, logUserInteraction } from "~vue/utils/logUtils";
import { computed, inject, nextTick, ref, useTemplateRef, watch } from "vue";

import ChatAgentToolFrame from "./ChatAgentToolFrame.vue";

const TRUNCATE_LENGTH = 200;

const props = defineProps({
    action: {
        type: Object,
        required: true,
    },
    lineIdx: { type: Number, required: true },
    messageId: { type: Number, required: true },
});

const { emitter, $sendEvent } = inject("globalProperties");
const { loading, start: startLoading, stop: stopLoading } = useDeferredLoading();
const { execute: executeAction } = useActionHandler();

const editing = ref(false);
const hasError = ref(false);
const content = ref(props.action.action_params.summary);
const baseTextareaRef = useTemplateRef("baseTextareaRef");

const needsTruncating = computed(() => content.value.length > TRUNCATE_LENGTH);
const expanded = ref(!needsTruncating.value);

const renderedSummary = computed(() => {
    if (!expanded.value && needsTruncating.value) {
        let base = content.value.substring(0, TRUNCATE_LENGTH);
        return base.substring(0, base.lastIndexOf(" "));
    }

    return content.value;
});

watch(editing, (value) => {
    if (value) {
        logUserInteraction("edit_email_summary");
        nextTick(() => baseTextareaRef.value?.textareaRef?.focus());
    }
});

function toggleEdit() {
    editing.value = !editing.value;
}

async function handleSubmit() {
    startLoading();
    hasError.value = false;

    const payload = {
        message_id: props.messageId,
        lineIdx: props.lineIdx,
        action_params: {
            summary: content.value,
        },
        action_state: {
            submitted: true,
        },
    };

    try {
        await $sendEvent(CHAT_EVENT.EMAIL_CHAT_SUMMARY, { content: content.value });
        await executeAction(ACTION.EMAIL_CHAT_SUMMARY, payload, { sync: true });
    } catch (e) {
        logError(e);
        hasError.value = true;
    } finally {
        stopLoading();
    }
}

function handleWrapUp() {
    emitter.emit(CHAT_EVENT.WRAP_UP_CHAT);
}
</script>
