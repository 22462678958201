<template>
    <ChatAgentToolFrame>
        <div class="chat-1 leading-[normal] flex flex-col gap-4">
            <div class="flex items-center">
                <div class="h-8 w-8">
                    <ProgressCircle v-bind="progressData" />
                </div>
                <div class="mr-2 ml-3 h-2 w-2 rounded-full bg-[#2AB756]"></div>
                <span class="text-[#2C2C2C]"> Action Item{{ action.action_params?.action_items.length === 1 ? "" : "s" }} added </span>
            </div>

            <div v-for="(item, idx) in action.action_params?.action_items" :key="`action-item-${idx}`" class="pl-1 flex items-center gap-4">
                <i class="text-xl leading-4 bi" :class="[item.checked ? 'bi-check-circle-fill text-[#555BA2]' : 'bi-circle text-[#8C8C8C]']"></i>
                <div class="text-[#8C8C8C]">{{ item.content }}</div>
            </div>
        </div>
    </ChatAgentToolFrame>
</template>

<script setup>
import ProgressCircle from "~vue/components/ProgressCircle.vue";
import { computed } from "vue";

import ChatAgentToolFrame from "./ChatAgentToolFrame.vue";

const props = defineProps({
    action: {
        type: Object,
        required: true,
        default: () => ({}),
    },
    lineIdx: { type: Number, required: true },
    messageId: { type: Number, required: true },
});

const progressData = computed(() => {
    let totalItems = 0;
    let totalChecked = 0;

    if (props.action.action_params?.action_items?.length > 0) {
        totalItems += props.action.action_params.action_items.length;
        totalChecked += props.action.action_params.action_items.filter(({ checked }) => checked).length;
    }

    const progress = totalChecked === 0 ? 5 : Math.floor((totalChecked / totalItems) * 100);
    return { progress, content: `${totalChecked}/${totalItems}` };
});
</script>
