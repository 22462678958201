<template>
    <ScheduleTrigger :date="formattedDate" :scheduled="isScheduled" @click="scheduleFollowupDialog.open()" />
    <ScheduleFollowupDialog ref="scheduleFollowupDialog" :initial-date="initialDate" :event-description="inviteDescription" :custom-schedule="scheduleFollowup" />
</template>

<script setup>
import { useScheduleFollowup } from "~vue/composables/useScheduleFollowup.js";
import { logUserInteraction } from "~vue/utils/logUtils";
import { inject, onMounted, useTemplateRef } from "vue";

import ScheduleFollowupDialog from "./components/ScheduleFollowupDialog.vue";
import ScheduleTrigger from "./components/ScheduleTrigger.vue";

const coachingSessionId = inject("coachingSessionId");

const { data, messageId, lineIdx } = defineProps({
    data: { type: Object, required: true },
    messageId: { type: Number, required: true },
    lineIdx: { type: Number, required: true },
});

const { initialDate, formattedDate, scheduleFollowup, isScheduled, inviteDescription } = useScheduleFollowup({
    line: data,
    messageId,
    lineIdx,
});

onMounted(() => {
    logUserInteraction("followup_presented", { chat_message_id: messageId }, coachingSessionId);
});

const scheduleFollowupDialog = useTemplateRef("scheduleFollowupDialog");
</script>
