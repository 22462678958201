<template>
    <!-- eslint-disable-next-line vue/no-v-html - html content sanitized below -->
    <div ref="targetDiv" class="chat-message-text" data-cy="chat-stream_message" v-html="content" />
</template>

<script>
// https://stackoverflow.com/questions/69951687/vue-3-defineprops-are-referencing-locally-declared-variables
// locally declared variables used in a compiler macro like defineProps need to be module scoped
const MODE = {
    WORDS: "words",
    CHARACTERS: "characters",
};
</script>

<script setup>
import { logUserInteraction } from "~vue/utils/logUtils";
import DOMPurify from "dompurify";
import { marked } from "marked";
import { computed, inject, onMounted, ref, watch } from "vue";

import { useChatStore } from "./stores/chatStore";

const { message, animate, rate, mode } = defineProps({
    message: {
        type: String,
        required: true,
    },
    // Whether to apply rendering effect.
    animate: {
        type: Boolean,
        default: false,
    },
    // Rate in milliseconds in which text is rendered, given the `mode`.
    rate: {
        type: Number,
        default: undefined,
        required: false,
    },
    // Mode in which text is rendered
    mode: {
        type: String,
        default: MODE.WORDS,
        validator(value) {
            return [MODE.WORDS, MODE.CHARACTERS].includes(value);
        },
    },
});

const coachingSessionId = inject("coachingSessionId");
const emit = defineEmits(["finishedRendering"]);

const cursorPosition = ref(0);
const running = ref(false);

const content = computed(() => {
    const slice = animate ? message.slice(0, cursorPosition.value) : message;
    const renderer = new marked.Renderer();
    renderer.link = function (href, title, text) {
        return `<a target="_blank" href="${href}" rel="nofollow noopener noreferrer">${text}</a>`;
    };
    return DOMPurify.sanitize(marked.parse(slice, { renderer, headerIds: false, mangle: false }), { ADD_ATTR: ["target"] });
});

onMounted(() => {
    if (!animate) {
        useChatStore.setSystemMessageAnimating(false);
        emit("finishedRendering");
        return;
    }

    running.value = true;
});

watch(
    () => message,
    (value) => {
        /*
         * Resume rendering of a partial if it had been rendered completely but a
         * new segment of the message was received since.
         */
        if (!running.value && value.length > cursorPosition.value) {
            running.value = true;
        }
    },
);

watch(running, (value, prev) => {
    if (!prev && value) {
        run();
    }
});

const run = () => {
    useChatStore.setSystemMessageAnimating(true);
    switch (mode) {
        case MODE.CHARACTERS:
            splitByCharacter();
            return;
        case MODE.WORDS:
            splitByWord();
            return;
    }
};

const splitByCharacter = () => {
    cursorPosition.value++;
    loop(splitByCharacter);
};

const splitByWord = () => {
    const segment = message.slice(cursorPosition.value);
    const nextIndex = segment.indexOf(" ");

    if (nextIndex === -1) {
        cursorPosition.value = message.length;
    } else {
        cursorPosition.value = cursorPosition.value + nextIndex + 1;
    }

    loop(splitByWord);
};

const loop = (next) => {
    /*
     * Stop looping if we've reached the end of the message. The method
     * in watch() will resume the loop if a new chunk is received.
     */
    if (cursorPosition.value >= message.length) {
        useChatStore.setSystemMessageAnimating(false);
        running.value = false;
        emit("finishedRendering");
        logUserInteraction("coach_message_rendered", {}, coachingSessionId);
        return;
    }

    /*
     * If no rate is specified, we defer the looping to
     * `requestAnimationFrame`, which is scheduled by the browser and
     * typically runs at around ~14ms.
     */
    if (rate) {
        window.setTimeout(next, rate);
    } else {
        window.requestAnimationFrame(next);
    }
};
</script>

<style scoped>
.chat-message-text :deep() {
    pre {
        /*
             * Markdown code fences may cause container to overflow.
             */
        width: 100%;
        white-space: pre-line;
        overflow-x: auto;
    }
}
</style>
