<template>
    <Transition name="fade">
        <div
            v-if="userMessageCount <= MAX_MESSAGES"
            class="bg-white rounded-full border-2 border-[#E8E8E8] px-6 py-3 shadow-md transition-all min-w-72"
            :class="{ 'opacity-0 delay-[2500ms]': userMessageCount === MAX_MESSAGES }">
            <div class="body-2 flex justify-between gap-4 mb-1">
                <Transition name="slide" mode="out-in">
                    <span :key="currentMessageShown">{{ currentMessageShown }}</span>
                </Transition>
                <span>{{ userMessageCount }}/{{ MAX_MESSAGES }}</span>
            </div>
            <div class="rounded-full w-full h-2 bg-[#ebebf4] relative">
                <div class="rounded-full h-2 gradient-bg absolute transition-all" :style="{ width: `${percentComplete}%` }"></div>
            </div>
        </div>
    </Transition>
</template>

<script setup>
import { computed } from "vue";
const { messages } = defineProps({
    messages: { type: Array, default: () => [] },
});

const MESSAGES = ["I'm here to listen", "This is helpful to hear", "Interesting, tell me more", "Trying to understand the root cause", "Forming my thoughts", "Ok, I'm ready"];

const MAX_MESSAGES = 5;

const userMessageCount = computed(() => messages.filter((m) => m.role === "user" && !m.hidden).length);
const currentMessageShown = computed(() => MESSAGES[userMessageCount.value]);
const percentComplete = computed(() => (userMessageCount.value / MAX_MESSAGES) * 100);
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 200ms ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.gradient-bg {
    background: linear-gradient(125deg, #ff2891 -22.98%, #003fdc 113.56%);
}

.slide-leave-active,
.slide-enter-active {
    transition: all 300ms ease-out;
}

.slide-leave-from,
.slide-enter-to {
    opacity: 1;
    transform: translateY(0);
}

.slide-enter-from {
    opacity: 0;
    transform: translateY(-1lh);
}
.slide-leave-to {
    opacity: 0;
    transform: translateY(1lh);
}
</style>
