import { useActionHandler } from "~vue/composables/useActionHandler";
import { logUserInteraction } from "~vue/utils/logUtils";
import { DateTime } from "luxon";
import { computed, inject, ref } from "vue";

export const useScheduleFollowup = ({ line, lineIdx, messageId }) => {
    const { execute: executeAction } = useActionHandler();

    const coachingSessionId = inject("coachingSessionId");
    const userDetails = inject("user_details");

    const date = ref(new Date(line.action_params?.event_at_confirmed ?? line.action_params?.event_at_guessed));

    const isScheduled = computed(() => line.action_state?.submitted);
    const inviteDescription = computed(() => line.action_params?.invite_description ?? "");

    const formattedDate = computed(() => {
        const luxonDate = DateTime.fromJSDate(date.value);
        return {
            day: luxonDate.day,
            month: luxonDate.monthShort,
            weekday: luxonDate.weekdayLong,
            time: luxonDate.toLocaleString(DateTime.TIME_SIMPLE),
        };
    });

    const scheduleFollowup = async ({ recurring, selectedDate, sync } = {}) => {
        const actionData = {
            message_id: messageId,
            lineIdx: lineIdx,
            action_params: {
                calendar_event_id: line.action_params.calendar_event_id,
                invite_description: inviteDescription.value,
                event_at_confirmed: DateTime.fromJSDate(selectedDate ?? date.value).toISO(),
                recurring: !!recurring || userDetails.value.prefers_weekly_check_in,
            },
            action_state: {
                submitted: true,
            },
        };
        const result = await executeAction("followup", actionData, { sync });
        if (selectedDate) {
            date.value = selectedDate;
        }

        logUserInteraction("followup_scheduled", {}, coachingSessionId);
        return result;
    };

    return {
        initialDate: date,
        formattedDate,
        scheduleFollowup,
        isScheduled,
        inviteDescription,
    };
};
