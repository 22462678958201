<template>
    <BaseDialog @cancel="$emit('cancel')" @close="$emit('close')">
        <button type="button" class="grid w-12 h-12 border-2 border-[#E8E8E8] rounded-full place-content-center absolute top-4 right-4" @click="$emit('close')">
            <X foreground-class="fill-[#8C8C8C]" height="12" width="12" />
        </button>
        <div v-if="currentStep === WRAP_UP_STEPS.SUMMARY">
            <div class="flex flex-col items-center gap-2 pt-6 md:hidden">
                <div class="w-12 h-12 md:w-16 md:h-16">
                    <CoachingModeMarble />
                </div>
                <h1 class="font-semibold text-2xl tracking-tighter">Here's a Recap</h1>
            </div>
            <!-- unmount-on-hide is set to false so that chat summary state persists through tab navigation while the dialog is open -->
            <BaseTabs :unmount-on-hide="false" class="block md:hidden p-6" :tab-ids="tabIds">
                <template #trigger-summary>
                    <div class="flex gap-4 items-center justify-center py-2">Summary</div>
                </template>
                <template #content-summary>
                    <div class="py-6 w-full"><ChatSummary @continue="handleContinue" /></div>
                </template>
                <template #trigger-notes>
                    <div class="flex gap-4 items-center justify-center py-2">Notes</div>
                </template>
                <template #content-notes>
                    <div class="py-6"><ChatRecap :insights="insights" :user-name="userName" :profile-inferences="profileInferences" /></div>
                </template>
            </BaseTabs>
            <div class="hidden md:grid md:grid-cols-2 md:min-w-[768px]">
                <div class="bg-[#F7FAFF] grid place-content-center">
                    <ChatRecap :insights="insights" :profile-inferences="profileInferences" class="p-10" :user-name="userName" />
                </div>
                <ChatSummary @continue="handleContinue" />
            </div>
        </div>
        <template v-else>
            <div class="flex flex-col gap-6 items-center justify-center md:max-w-[500px] min-h-[400px] p-6 md:p-10">
                <div class="w-16 h-16">
                    <CoachingModeMarble />
                </div>
                <h1 class="font-semibold text-2xl tracking-tighter">{{ wrapUpTitle }}</h1>
                <p class="font-medium text-base text-[#8C8C8C] text-center">{{ wrapUpDescription }}</p>
                <template v-if="currentStep === WRAP_UP_STEPS.SCHEDULE">
                    <ScheduleFollowUp :date="date" :recurring="recurring" @date-update="handleDateUpdated" @recurring-update="handleRecurringUpdated" />
                    <div class="flex flex-col gap-2">
                        <BaseButton theme="primary" :disabled="isLoadingEventDescription" @click="scheduleFollowup">Schedule</BaseButton>
                        <BaseButton theme="secondary" @click="handleSkip">Skip</BaseButton>
                    </div>
                </template>
                <BaseButton v-else-if="currentStep === WRAP_UP_STEPS.COMPLETE" theme="primary" @click="openUrl('/')">Go to home</BaseButton>
            </div>
        </template>
    </BaseDialog>
</template>

<script setup>
import { nextWorkday, roundToNearest15 } from "/js/dateUtils";
import { ACTION, pluckAction, pluckMultipleActions } from "~vue/chatActions";
import BaseButton from "~vue/components/BaseButton.vue";
import BaseDialog from "~vue/components/BaseDialog.vue";
import BaseTabs from "~vue/components/BaseTabs.vue";
import CoachingModeMarble from "~vue/components/navigation/CoachingModeMarble.vue";
import X from "~vue/icons/X.vue";
import ScheduleFollowUp from "~vue/ScheduleFollowUp.vue";
import { openUrl } from "~vue/utils";
import { logError, logUserInteraction } from "~vue/utils/logUtils";
import { convertSnakeCaseToDisplay } from "~vue/utils/stringUtils";
import { DateTime } from "luxon";
import { computed, inject, onMounted, ref } from "vue";

import ChatRecap from "./ChatRecap.vue";
import ChatSummary from "./ChatSummary.vue";

const coachingSessionId = inject("coachingSessionId");
const { $sendEvent } = inject("globalProperties");
const userDetails = inject("user_details");

const { messages, userFirstName, userLastName } = defineProps({
    messages: { type: Array, required: true },
    userFirstName: { type: String, default: "" },
    userLastName: { type: String, default: "" },
});

const WRAP_UP_STEPS = {
    SUMMARY: 0,
    SCHEDULE: 1,
    COMPLETE: 2,
};

const currentStep = ref(WRAP_UP_STEPS.SUMMARY);

defineEmits(["close", "cancel"]);

const tabIds = ["summary", "notes"];

const insights = computed(() => pluckMultipleActions(messages, [ACTION.INTERNAL_THINKING, ACTION.VALUES_INSIGHT]));
const profileInferences = computed(() => {
    const profileInferenceActions = pluckMultipleActions(messages, [ACTION.INFERRED_PROFILE_ANSWER]);
    return profileInferenceActions
        .filter((action) => !!action.line.action_params)
        .map(({ line: { action_params } }) => ({
            id: action_params.user_profile_data_id,
            profile_question_key: convertSnakeCaseToDisplay(action_params.key),
            value: action_params.inferred_answer,
        }));
});

const followup = computed(() => pluckAction(messages, ACTION.FOLLOW_UP)?.line);
const handleContinue = () => {
    if (followup.value) {
        if (!followup.value.action_params.event_at_confirmed && !followup.value.action_state.submitted) {
            currentStep.value = WRAP_UP_STEPS.SCHEDULE;
            return;
        }
        currentStep.value = WRAP_UP_STEPS.COMPLETE;
        return;
    }
    currentStep.value = WRAP_UP_STEPS.SCHEDULE;
};

const userName = computed(() => {
    if (userFirstName && userLastName) {
        return `${userFirstName} ${userLastName}`;
    }
    return userFirstName ?? userLastName;
});

const followupIsoDate = computed(() => followup.value?.action_params.event_at_confirmed ?? followup.value?.action_params.event_at_guessed ?? DateTime.now().plus({ days: 3 }));
const date = ref(nextWorkday(roundToNearest15(followupIsoDate.value)).toJSDate());
const recurring = ref(userDetails.prefers_weekly_check_in);
const isLoadingEventDescription = ref(false);
const followUpDescription = ref("");

const handleDateUpdated = (newDate) => {
    date.value = newDate;
};
const handleRecurringUpdated = (newRecurring) => {
    recurring.value = newRecurring;
};
onMounted(async () => {
    logUserInteraction("session_summary_viewed", {}, coachingSessionId);
    try {
        const response = await $sendEvent("generate_exit_follow_up_copy");
        followUpDescription.value = response.payload;
    } finally {
        isLoadingEventDescription.value = false;
    }
});

const scheduledFollowup = ref(!!followup.value?.action_params.event_at_confirmed);

const scheduleFollowup = () => {
    currentStep.value = WRAP_UP_STEPS.COMPLETE;
    try {
        $sendEvent("schedule_follow_up", {
            event_description: followUpDescription.value,
            event_at_confirmed: DateTime.fromJSDate(date.value).toISO(),
            recurring: recurring.value,
            type: "followup",
            id: 0,
        });
        scheduledFollowup.value = true;
        logUserInteraction("session_summary_followup_scheduled", {}, coachingSessionId);
    } catch (e) {
        logError(e);
    }
};

const handleSkip = () => {
    currentStep.value = WRAP_UP_STEPS.COMPLETE;
};

const wrapUpTitle = computed(() => {
    if (currentStep.value === WRAP_UP_STEPS.SCHEDULE) {
        return userFirstName ? `Nice work, ${userFirstName}!` : "Nice work!";
    }
    if (scheduledFollowup.value) {
        const luxonDate = DateTime.fromJSDate(date.value);
        const diffDays = luxonDate.diffNow("days").days;

        return `Ok great! See you ${DateTime.now().plus({ days: diffDays }).toRelative()} at ${luxonDate.toLocaleString(DateTime.TIME_SIMPLE)}!`;
    }
    return "See you next time";
});
const wrapUpDescription = computed(() => {
    if (currentStep.value === WRAP_UP_STEPS.SCHEDULE) {
        return "I'll check in with you weekly so you'll always have someone in your corner to process, plan, and problem-solve.";
    }
    if (scheduledFollowup.value) {
        const luxonDate = DateTime.fromJSDate(date.value);
        return `I have sent an invite to your calendar for ${luxonDate.toFormat("LLL dd")} at ${luxonDate.toLocaleString(DateTime.TIME_SIMPLE)}. This is a ${recurring.value ? "recurring" : "one-time"} event.`;
    }
    return "I'll check in with you in the coming week to see how things are going.";
});
</script>
