<template>
    <div class="flex flex-col gap-6">
        <h1 class="font-semibold text-2xl tracking-tighter pt-10 px-10 hidden md:inline">Chat Summary</h1>
        <Transition name="fade" mode="out-in">
            <div v-if="isLoadingEmailSummary" class="flex flex-col gap-2 min-h-60 px-10">
                <template v-for="i in 4" :key="i">
                    <SkeletonLoader class="w-full h-4 rounded-md" />
                </template>
                <SkeletonLoader class="w-[240px] h-4 rounded-md" />
            </div>
            <div v-else-if="!!emailSummaryError">
                <div class="flex flex-col gap-2 items-center tracking-tight mt-auto md:pb-10">
                    <div class="text-red-400 font-semibold max-w-80">{{ emailSummaryError }}</div>
                    <BaseButton theme="secondary" @click="generateEmailSummary">Retry</BaseButton>
                </div>
            </div>
            <textarea
                v-else
                v-model="summaryText"
                class="border-2 border-gray-200 md:border-none resize-none p-6 md:px-10 md:py-0 w-full whitespace-pre-line leading-normal rounded-xl md:rounded-none font-semibold tracking-tight text-[#8C8C8C] outline-none"
                rows="10"
                name="summary"
                placeholder="What do you think would make our conversations more effective?"
                autofocus
                required
                autocomplete="off" />
        </Transition>
        <div class="flex flex-col gap-2 items-center tracking-tight mt-auto md:pb-10">
            <HorizontalDivider class="border-2 hidden md:block" />
            <BaseButton theme="primary" :disabled="isSending || isEmailSent || isLoadingEmailSummary" @click="sendEmailSummary">
                <template v-if="isSending">
                    <div class="flex items-center gap-2"><LoadingSpinner width="16" height="16" /> Email Sending</div>
                </template>
                <template v-else-if="isEmailSent"> Email Sent! </template>
                <template v-else-if="isEmailSendError">
                    <div class="flex items-center gap-2"><i class="bi bi-arrow-clockwise" /> Try Again</div>
                </template>
                <template v-else>
                    <div class="flex items-center gap-2"><i class="bi bi-send" /> Email Summary</div>
                </template>
            </BaseButton>
            <BaseButton theme="secondary" @click="$emit('continue')">Wrap up</BaseButton>
        </div>
    </div>
</template>

<script setup>
import BaseButton from "~vue/components/BaseButton.vue";
import HorizontalDivider from "~vue/components/HorizontalDivider.vue";
import SkeletonLoader from "~vue/components/SkeletonLoader.vue";
import { CHAT_EVENT } from "~vue/events";
import LoadingSpinner from "~vue/icons/LoadingSpinner.vue";
import { inject, onMounted, ref } from "vue";

defineEmits(["continue"]);

const { $sendEvent } = inject("globalProperties");

const isLoadingEmailSummary = ref(true);
const summaryText = ref("");
const emailSummaryError = ref("");

const generateEmailSummary = async () => {
    isLoadingEmailSummary.value = true;
    const payload = await $sendEvent(CHAT_EVENT.GENERATE_CHAT_SUMMARY);
    summaryText.value = payload.payload;
    isLoadingEmailSummary.value = false;
};

onMounted(async () => {
    try {
        await generateEmailSummary();
    } catch (e) {
        emailSummaryError.value = "Oops! Something went wrong with creating an email summary, please try again.";
        isLoadingEmailSummary.value = false;
    }
});

const isSending = ref(false);
const isEmailSent = ref(false);
const isEmailSendError = ref(false);

const sendEmailSummary = async () => {
    try {
        isSending.value = true;
        await $sendEvent(CHAT_EVENT.EMAIL_CHAT_SUMMARY, { content: summaryText.value });
        isEmailSent.value = true;
    } catch (e) {
        isEmailSendError.value = true;
    } finally {
        isSending.value = false;
    }
};
</script>

<style scoped>
.iconBold {
    -webkit-text-stroke: 1px;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 200ms ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
