<template>
    <div class="flex flex-col justify-end text-left gap-10 antialiased mb-2 pl-3 md:pl-16">
        <div>
            <p class="text-[#8C8C8C] heading-3">{{ dateToday }}</p>
            <h3 class="heading-1">{{ greetMessageWithUser }}</h3>
            <h3 class="heading-1 text-[#8C8C8C]">How can I help you?</h3>
        </div>
    </div>
</template>

<script setup>
import { humanizedTimeOfDay } from "/js/dateUtils";
import capitalize from "lodash/capitalize";
import { DateTime } from "luxon";
import { computed } from "vue";

const { name } = defineProps({
    name: { type: String, default: "" },
});

const dateToday = DateTime.now().toFormat("LLL dd, yyyy");
const currentTimeOfDay = capitalize(humanizedTimeOfDay());
const greetMessageWithUser = computed(() => `Good ${currentTimeOfDay},${name ? ` ${name}` : ""}`);
</script>
