<template>
    <div
        id="chat-container__stream"
        :class="{ 'hidden md:flex': showWidgetSidebar, 'overflow-y-auto': showOverflow, 'overflow-y-hidden': !showOverflow }"
        class="grow md:flex snap-y scroll-pb-5 md:scroll-pb-10 overflow-x-hidden">
        <div class="w-full chat_component rounded-xl round md:max-w-screen-md md:mx-auto">
            <div class="relative messages px-4 sm:mt-8 md:px-8">
                <div class="relative">
                    <div class="blurEffect"></div>
                    <Teleport to="body">
                        <Transition name="fade">
                            <div v-if="!showStartLoadingMessage && !hasActivatedCoachingSession" class="fixed top-8 inset-x-0 w-fit mx-auto z-10">
                                <ChatActivation :messages="messages" />
                            </div>
                        </Transition>
                    </Teleport>
                    <SplashScreen :name="userFirstName" />
                    <Transition name="fade">
                        <div v-if="!showStartLoadingMessage" class="fs-mask" data-cy="chat-stream">
                            <template v-for="(message, idx) in messages" :key="idx">
                                <template v-if="message.role !== 'tool'">
                                    <template v-for="(line, lineIdx) in message.lines" :key="lineIdx">
                                        <div v-if="shouldRenderLine(line)" class="chat group/chat" :class="showAsAssistantMessage(message, line) ? 'chat-start' : 'chat-end'">
                                            <div v-if="isLastIndexOfUserMessage(message.lines, lineIdx) && hasFileActionInLines(message.lines)" class="flex my-1 gap-2 justify-end">
                                                <div
                                                    v-if="isOnlyFileUploadActionInLines(message.lines) && userName"
                                                    class="min-w-10 max-w-10 min-h-10 max-h-10 rounded-full font-semibold text-base text-[#555BA2] bg-[#E0E9FF] flex items-center justify-center order-last">
                                                    {{ getFirstLastInitials(userName) }}
                                                </div>
                                                <div
                                                    class="overflow-auto max-w-xs sm:max-w-lg md:max-w-screen-sm flex gap-2"
                                                    :class="{
                                                        'mr-0': isOnlyFileUploadActionInLines(message.lines),
                                                        'mr-12': !isOnlyFileUploadActionInLines(message.lines),
                                                    }">
                                                    <FileAttachedTag v-for="file in getFileActionInLines(message.lines)?.action_params" :key="file.name" :file="file" />
                                                </div>
                                            </div>
                                            <div
                                                class="flex gap-2"
                                                :class="{
                                                    'flex-row': message.role === 'user',
                                                    'flex-col md:flex-row': message.role === 'assistant',
                                                    'row-start-2': hasFileActionInLines(message.lines),
                                                }">
                                                <div v-if="showAsAssistantMessage(message, line)" class="min-w-12 max-w-12 min-h-12 max-h-12 -mb-4 md:mb-0 mt-4 -mr-2">
                                                    <CoachingModeMarble />
                                                </div>
                                                <div
                                                    v-else-if="message.role === 'user' && line.type !== 'action' && userName"
                                                    class="min-w-10 max-w-10 min-h-10 max-h-10 rounded-full font-semibold text-base text-[#555BA2] bg-[#E0E9FF] flex items-center justify-center order-last">
                                                    {{ getFirstLastInitials(userName) }}
                                                </div>
                                                <div
                                                    v-if="shouldRenderLineContent(line)"
                                                    :ref="(el) => (messageRefs[refForMessage(message.chat_message_id)] = el)"
                                                    class="max-w-full px-4 prose-p:my-4 relative ease-in-out duration-500 transition-colors"
                                                    :class="{
                                                        'text-left rounded-3xl rounded-tr-lg border-2 border-[#E8E8E8]': !showAsAssistantMessage(message, line),
                                                        'bg-white': !showAsAssistantMessage(message, line) && message.chat_message_id !== focusedMessage,
                                                        'bg-valence-purple/10': message.chat_message_id === focusedMessage,
                                                    }">
                                                    <article class="prose w-full font-medium text-base md:text-xl tracking-tight md:tracking-tighter text-left">
                                                        <ChatMessageText
                                                            v-if="line.type === 'md'"
                                                            :message="line.content"
                                                            :animate="shouldAnimateMessageText(message, idx)"
                                                            @finished-rendering="handleLineRendered(message, idx, lineIdx)" />
                                                        <ChatMessageToolCall
                                                            v-if="lineIsAction(line) && line.action_name === ACTION.TOOL_CALL"
                                                            :tool="toolCalls.get(line.action_params?.tool_call_id)" />
                                                        <ChatLoadingFrame
                                                            v-if="lineIsAction(line) && line.action_name === ACTION.LOADING"
                                                            class="mt-4"
                                                            :is-loading="!line.action_params?.loaded">
                                                            {{ line.action_params?.content }}
                                                        </ChatLoadingFrame>
                                                        <div v-if="line.type == 'html'" data-cy="chat-stream_message" v-html="line.content" />
                                                        <ChatMessageReactionBar
                                                            v-if="shouldIncludeReactionBar(message, lineIdx)"
                                                            :message="message"
                                                            :line-idx="lineIdx"
                                                            :show-thought-process="idx === messages.length - 1"
                                                            class="invisible group-hover/chat:visible"
                                                            @thought-process-clicked="handleThoughtProcessClicked" />
                                                    </article>
                                                    <Transition name="fade">
                                                        <template v-if="showRolePlayInsightButton(message)">
                                                            <button
                                                                type="button"
                                                                class="bg-[#F7FAFF]/75 hover:bg-[#F7FAFF] text-sm font-medium mb-2 px-2 py-1 rounded-full text-[#555BA2] border-2 border-[#555BA266]"
                                                                @click="focusRolePlayFeedback(message.chat_message_id)">
                                                                View insight
                                                            </button>
                                                        </template>
                                                    </Transition>
                                                </div>
                                            </div>
                                        </div>
                                        <ChatActionAnswers
                                            v-if="shouldShowAnswers(line)"
                                            :data="line"
                                            :message-id="message.chat_message_id"
                                            :line-idx="lineIdx"
                                            :role="message.role"
                                            class="mb-4" />
                                        <ChatActionScenarios
                                            v-if="shouldShowSuggestedScenarios(line)"
                                            :data="line"
                                            :message-id="message.chat_message_id"
                                            :line-idx="lineIdx"
                                            :role="message.role"
                                            class="mb-4" />
                                        <ChatActionFocusAreas
                                            v-if="shouldShowFocusAreas(line)"
                                            :data="line"
                                            :message-id="message.chat_message_id"
                                            :line-idx="lineIdx"
                                            class="mb-4" />
                                        <Transition name="fade">
                                            <ChatNotificationMessage
                                                v-if="shouldShowNotification(message, line, lineIdx)"
                                                v-bind="getNotificationMessageProps(message, line, lineIdx)"
                                                class="mb-4" />
                                        </Transition>
                                        <Transition>
                                            <InsightsCard
                                                v-if="shouldShowOnboardingInsight(line)"
                                                card-type="inChat"
                                                :title="line.action_params?.title"
                                                :subtitle="line.action_params?.subtitle"
                                                :content="line.action_params?.content" />
                                        </Transition>
                                    </template>
                                </template>
                            </template>
                        </div>
                    </Transition>
                    <CoachingModeMarble v-if="shouldShowLoadingMessageMarble" class="mt-4" size="small" is-loading />
                    <ChatWaitingMessage v-else-if="showSlowResponse" class="my-4">One moment while I gather my thoughts. </ChatWaitingMessage>
                    <ChatLoadingMessage v-else-if="showStartLoadingMessage" class="my-4" :loading-message-stack="loadingMessageStack" @complete="handleLoadingMessageComplete" />
                    <div ref="bottomAnchor" class="pb-24 md:pb-16" :style="{ height: bottomHeight }" />
                </div>
                <Transition name="fade">
                    <div v-if="!isBottomVisible" class="h-0 sticky bottom-2">
                        <button
                            type="button"
                            class="leading-none absolute w-fit bottom-0 left-1/2 -translate-x-1/2 text-sm font-semibold tracking-tight bg-white rounded-badge text-[#8C8C8C] py-3 px-6 border-2 border-[#E8E8E8]"
                            @click="scrollToLastMessage">
                            scroll <i class="bi bi-chevron-down icon-bold"></i>
                        </button>
                    </div>
                </Transition>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useElementVisibility } from "@vueuse/core";
import { logUserInteraction } from "~vue/utils/logUtils.js";
import { getFirstLastInitials } from "~vue/utils/stringUtils";
import { computed, inject, nextTick, onMounted, onUnmounted, ref, useTemplateRef, watch } from "vue";

import ChatActionAnswers from "./ChatActionAnswers.vue";
import ChatActionFocusAreas from "./ChatActionFocusAreas.vue";
import {
    ACTION,
    getPreviousMessage,
    getRolePlayMonitorParams,
    isActionableLookbackAction,
    isLookbackAction,
    isRolePlayMessage,
    lineHasNotification,
    lineIsAction,
    lineIsDismissed,
} from "./chatActions.js";
import ChatActionScenarios from "./ChatActionScenarios.vue";
import ChatActivation from "./ChatDetail/ChatActivation.vue";
import SplashScreen from "./ChatDetail/SplashScreen.vue";
import ChatLoadingFrame from "./ChatLoadingFrame.vue";
import ChatLoadingMessage from "./ChatLoadingMessage.vue";
import ChatMessageReactionBar from "./ChatMessageReactionBar.vue";
import ChatMessageText from "./ChatMessageText.vue";
import ChatMessageToolCall from "./ChatMessageToolCall.vue";
import ChatNotificationMessage from "./ChatNotificationMessage.vue";
import ChatWaitingMessage from "./ChatWaitingMessage.vue";
import FileAttachedTag from "./components/FileAttachedTag.vue";
import CoachingModeMarble from "./components/navigation/CoachingModeMarble.vue";
import { CHAT_EVENT } from "./events.js";
import InsightsCard from "./Onboarding/InsightsCard.vue";

const coachingSessionId = inject("coachingSessionId");
const { emitter } = inject("globalProperties");
const {
    loadingMessageStack,
    dismissSuggestedScenarios,
    messages,
    numberOfMessagesAtInit,
    showOverflow,
    showSlowResponse,
    showStartLoadingMessage,
    showWidgetSidebar,
    followUpNotificationData,
    userFirstName,
    userLastName,
    hasActivatedCoachingSession,
} = defineProps({
    loadingMessageStack: { type: Array, default: () => [] },
    dismissSuggestedScenarios: { type: Boolean },
    messages: { type: Array, default: () => [] },
    numberOfMessagesAtInit: { type: Number, default: 0 },
    showOverflow: { type: Boolean, default: true },
    showSlowResponse: { type: Boolean },
    showStartLoadingMessage: { type: Boolean },
    showWidgetSidebar: { type: Boolean },
    followUpNotificationData: { type: Object, default: () => ({}) },
    userFirstName: { type: String, default: undefined },
    userLastName: { type: String, default: undefined },
    hasActivatedCoachingSession: { type: Boolean },
});

const emit = defineEmits(["loading-complete"]);

const MIN_MESSAGES_FOR_SUGGESTED_SCENARIOS = 2;

const focusedMessage = ref(null);
const renderedLines = ref(new Set());

watch(
    () => followUpNotificationData,
    () => {
        logUserInteraction("follow_up_notification_presented", {}, coachingSessionId);
    },
);

const shouldShowLoadingMessageMarble = ref(false);

const bottomHeight = ref(0);
watch(
    () => messages,
    () => {
        if (messages.length > 0) {
            const latestMessage = messages[messages.length - 1];
            if (latestMessage.role === "user" && !latestMessage.hidden) {
                shouldShowLoadingMessageMarble.value = true;
                // 100dvh - 400px accounts for the amount of bottom padding needed to allow the user message to scroll to top
                // 400px is roughly the height of the top and bottom portions that need to be taken into account, on both desktop and mobile
                bottomHeight.value = `calc(100dvh - 400px)`;
            } else if (latestMessage.role === "assistant") {
                shouldShowLoadingMessageMarble.value = false;
            }
        }
    },
    { deep: true },
);
watch(
    () => showStartLoadingMessage,
    (newValue) => {
        if (!newValue) {
            // similar to the above bottomHeight logic, 750px handles roughly the amount of padding needed to scroll the starting splash screen up
            bottomHeight.value = `calc(100dvh - 750px)`;
            nextTick(() => {
                scrollToLastMessage();
            });
        }
    },
);

onMounted(() => {
    emitter.on(CHAT_EVENT.FOCUS_CHAT_MESSAGE, handleFocusChatMessage);
});

onUnmounted(() => {
    emitter.off(CHAT_EVENT.FOCUS_CHAT_MESSAGE, handleFocusChatMessage);
});

const toolCalls = computed(() => {
    /*
     * Computes a map that contains all existing tool calls
     * and their associated data. The map looks like this:
     *
     * [key: string]: {
     *    // Name of the tool. Matches the corresponding action's name.
     *    name: String,
     *    // Has the tool completed its run?
     *    completed: Boolean,
     *    // The chat message action containing the tool's returned data
     *    action: ChatMessageAction,
     *    // Message id of the message containing the tool's returned data
     *    messageId: Number,
     *    // Message line index of containing the tool's chat message action
     *    lineIdx: Number
     * }
     *
     * This function runs through `messages` once and constructs the tool call
     * based on the current state of the message stack. The data associated to
     * a tool run is spread across multiple messages. When a tool call is requested,
     * the back-end creates an `assistant` message that has a `tool` line. At this
     * point we know that a tool run for a given action is taking place, and so
     * create the "running" state for the tool in this map. We only know that tool run's
     * id and the name of the action that will eventually unfold.
     *
     * When a tool run completes, the back-end creates a `tool` message that has an
     * `action` line containing the result of the tool call. We must inspect the
     * action's `action_params` to detect a tool call id. If found, the tool call
     * state is updated to mark it as completed and populate the
     * corresponding ChatMessageAction.
     */
    const map = new Map();

    for (let message of messages) {
        if (!["assistant", "tool"].includes(message.role)) {
            continue;
        }

        if (message.role === "assistant") {
            for (let line of message.lines) {
                if (!lineIsAction(line)) {
                    continue;
                }

                if (line.action_name === ACTION.TOOL_CALL && !map.has(line.action_params?.tool_call_id)) {
                    map.set(line.action_params.tool_call_id, {
                        name: line.action_params.tool_name,
                        completed: false,
                        action: null,
                        messageId: null,
                        lineIdx: null,
                    });
                }
            }
        }

        if (message.role === "tool") {
            for (let line of message.lines) {
                if (!lineIsAction(line)) {
                    continue;
                }

                if (line.action_params?.tool_call_id && map.has(line.action_params.tool_call_id)) {
                    map.set(line.action_params.tool_call_id, {
                        ...map.get(line.action_params.tool_call_id),
                        completed: true,
                        action: line,
                        messageId: message.chat_message_id,
                        lineIdx: message.lines.indexOf(line),
                    });
                }
            }
        }
    }

    return map;
});

const messageRefs = ref({});
const refForMessage = (chatMessageId) => `m-${chatMessageId}`;

const handleFocusChatMessage = ({ chatMessageId }) => {
    const messageRef = messageRefs.value[refForMessage(chatMessageId)];
    if (messageRef && messageRef instanceof HTMLElement) {
        messageRef.scrollIntoView({ block: "center", behavior: "smooth" });
        focusedMessage.value = chatMessageId;
        nextTick(() => {
            setTimeout(() => {
                focusedMessage.value = null;
            }, 1000);
        });
    }
};

const showRolePlayInsightButton = (message) => {
    if (!isRolePlayMessage(message)) {
        return false;
    }

    const params = getRolePlayMonitorParams(message);
    return params && !!params.feedback;
};

const focusRolePlayFeedback = (chatMessageId) => {
    // Open the sidebar if it's closed, and focus the feedback
    // on the next tick.
    emitter.emit(CHAT_EVENT.FOCUS_WIDGET, { actionName: ACTION.ROLE_PLAY_MONITOR });
    nextTick(() => {
        emitter.emit(CHAT_EVENT.FOCUS_ROLE_PLAY_MESSAGE_FEEDBACK, chatMessageId);
    });
};

const getMessageIdLineIdxStr = (messageId, lineIdx) => `${messageId}-${lineIdx}`;
const handleLoadingMessageComplete = () => emit("loading-complete");
const handleLineRendered = (message, messageIndex, lineIdx) => {
    renderedLines.value.add(getMessageIdLineIdxStr(message.id, lineIdx));
};

const handleThoughtProcessClicked = (message) => {
    emitter.emit(CHAT_EVENT.OPEN_THOUGHT_PROCESS_DIALOG, message.id);
};

const shouldAnimateMessageText = (message, messageIndex) => {
    /* Only animate a message from the LLM that is being
     * streamed in real-time.
     */
    if (message.role !== "assistant") {
        return false;
    }

    if (messageIndex < numberOfMessagesAtInit) {
        return false;
    }

    return messageIndex >= messages.length - 1;
};

const shouldShowAnswers = (line) => lineIsAction(line) && line.action_name === ACTION.ANSWERS;
const shouldShowFocusAreas = (line) => lineIsAction(line) && line.action_name === ACTION.FOCUS_AREA_PICKER && !line.action_state.dismissed;
const shouldShowSuggestedScenarios = (line) =>
    lineIsAction(line) && line.action_name === ACTION.SCENARIOS && !dismissSuggestedScenarios && messages.length <= MIN_MESSAGES_FOR_SUGGESTED_SCENARIOS;
const shouldIncludeReactionBar = (message, lineIdx) => {
    if (!message.lines) {
        return;
    }
    const line = message.lines[lineIdx];
    if (!line) {
        return;
    }
    const isCoach = message.role === "assistant";
    const isTextLine = line.type === "md" || line.type === "html";
    const isFinishedRendering = renderedLines.value.has(getMessageIdLineIdxStr(message.id, lineIdx));
    return !line.hidden && isCoach && isTextLine && isFinishedRendering;
};
const hasFileActionInLines = (lines) => lines.some((l) => l?.type === "action" && l?.action_name === ACTION.FILE_UPLOAD);
const isVisibleMessageLineOrFileUpload = (line) => {
    return !line.hidden && (line.type !== "action" || line.action_name === ACTION.FILE_UPLOAD);
};
const isLastIndexOfUserMessage = (lines, idx) => lines.findLastIndex((l) => isVisibleMessageLineOrFileUpload(l)) === idx;
const getFileActionInLines = (lines) => lines.find((l) => l?.type === "action" && l?.action_name === ACTION.FILE_UPLOAD && l?.action_params.length > 0);
const isOnlyFileUploadActionInLines = (lines) => {
    if (lines.length === 1) {
        const line = lines[0];
        return line.type === "action" && line.action_name === ACTION.FILE_UPLOAD;
    }
    return false;
};
const userName = computed(() => {
    if (userFirstName && userLastName) {
        return `${userFirstName} ${userLastName}`;
    }
    return userFirstName ?? userLastName;
});

const shouldShowNotification = (message, line, lineIdx) => {
    const role = message.role;
    if (role === "assistant") {
        if (lineIdx !== 0) {
            // only show a lookback notification on the first line of the message to prevent duplicates
            return false;
        }
        const prevUserMessage = getPreviousMessage(messages, message.id, "user");
        if (!prevUserMessage) {
            // short circuits if there is no previous user message found
            return false;
        }
        return prevUserMessage.lines.find(isActionableLookbackAction) != null;
    } else if (role === "user") {
        return lineHasNotification(line) && !isLookbackAction(line) && !lineIsDismissed(line);
    }
    return false;
};

const shouldShowOnboardingInsight = (line) => {
    return lineIsAction(line) && line.action_name === ACTION.ONBOARDING_INSIGHT;
};

// function should only be called if the above shouldShowNotification function returns true
const getNotificationMessageProps = (message, line, lineIdx) => {
    const role = message.role;
    if (role === "assistant") {
        const prevMessage = getPreviousMessage(messages, message.id, "user");
        const prevLineWithLookbackActionIndex = prevMessage.lines.findIndex(isActionableLookbackAction);
        const prevLineWithLookbackAction = prevMessage.lines[prevLineWithLookbackActionIndex];
        return {
            line: prevLineWithLookbackAction,
            lineIdx: prevLineWithLookbackActionIndex,
            messageId: prevMessage.id,
        };
    }

    return {
        line,
        lineIdx,
        messageId: message.id,
    };
};

const bottom = useTemplateRef("bottomAnchor");
const scrollToLastMessage = () => {
    /*
     * We target an empty div at the end of the message list. This
     * div has some padding to add additional space when scrolling.
     * This is done to prevent cutting off the last message right
     * at the edge of the scrollable area.
     *
     * Why not target the last message instead? Besides having to maintain additional logic to
     * retrieve the DOM element of the last message, we want to keep the aforementioned
     * spacing without adding/removing it from individual message elements.
     */
    if (bottom.value) {
        bottom.value.scrollIntoView({ behavior: "smooth", block: "end" });
    }
};
defineExpose({ scrollToLastMessage });

const isToolOrLoadingAction = (line) => {
    return lineIsAction(line) && [ACTION.TOOL_CALL, ACTION.LOADING].includes(line.action_name);
};

const showAsAssistantMessage = (message, line) => {
    return message.role === "assistant" || (lineIsAction(line) && line.action_name === ACTION.LOADING);
};

const shouldRenderLine = (line) => {
    return line.type !== "tool" && (isVisibleMessageLineOrFileUpload(line) || isToolOrLoadingAction(line));
};

const shouldRenderLineContent = (line) => {
    /*
     * The LOADING and TOOL_CALL actions are the only actions that are rendered inline as part of the chat message.
     */
    return isToolOrLoadingAction(line) || !lineIsAction(line);
};

const isBottomVisible = useElementVisibility(bottom);
</script>

<style scoped>
.chat_component .chat {
    :deep(article.prose p) {
        word-break: break-word;
    }
}

.chat_component .messages {
    height: 100%;
    max-height: 80vh;
}

.chat_component .text-valence-purple {
    color: #bc63e8;
}

.blurEffect {
    @apply z-10 sticky h-16 top-0 left-0 w-full;
    background: linear-gradient(180deg, #fff, transparent);
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 200ms ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
