<template>
    <div class="fixed z-50 left-1/4 top-0 right-0 h-4" @mouseover="handleExitIntentMouseover"></div>
    <dialog id="exit-intent-dialog" ref="dialogElement" class="focus-visible:outline-0 bg-white md:p-16 p-4 rounded-xl text-valence-grey-800 relative" @cancel="cancel">
        <button :disabled="submitLoading" type="button" class="absolute top-2 right-2 p-3" @click="dismiss">
            <i class="bi bi-x text-xl text-valence-grey-600 hover:text-valence-grey-600/80" :class="{ 'text-valence-grey-600/80': submitLoading }"></i>
        </button>
        <div class="flex flex-col gap-6 text-center max-w-prose">
            <div class="flex flex-col gap-2">
                <div class="text-2xl font-semibold tracking-tighter">Do you need to run?</div>
                <div v-if="loading" class="flex justify-center">
                    <LoadingSpinner class="h-12" />
                </div>
                <p v-else class="max-w-xs mx-auto text-base" v-text="copy"></p>
            </div>
            <p v-if="submitError" class="max-w-xs text-sm text-red-400">Sorry, I&apos;m having trouble scheduling our follow-up. Please try again later.</p>
            <div v-if="!loading" class="flex flex-col gap-4">
                <ScheduleFollowUp :date="inputDate" :recurring="recurring" @date-update="onDateUpdate" @recurring-update="onRecurringUpdate" />
                <button
                    :disabled="submitLoading"
                    type="button"
                    autofocus
                    class="self-center rounded-full text-white bg-[#555BA2] hover:bg-[#555BA2]/80 font-bold text-sm py-3 px-16 leading-6"
                    :class="{ 'bg-[#555BA2]/80': submitLoading }"
                    @click="schedule">
                    <div v-if="submitLoading" class="h-5 w-5 inline-block mx-auto">
                        <LoadingSpinner />
                    </div>
                    <template v-else> Schedule </template>
                </button>
            </div>
            <button
                v-if="!loading"
                :disabled="submitLoading"
                type="button"
                class="text-xs underline text-valence-grey-800/75 hover:text-valence-grey-800/50"
                :class="{ 'text-valence-grey-800/50': submitLoading }"
                @click="dismiss">
                Never mind, I can keep chatting now
            </button>
        </div>
    </dialog>
</template>

<script>
import { logUserInteraction } from "~vue/utils/logUtils.js";
import { DateTime } from "luxon";

import { nextWorkday, roundToNearest15 } from "../dateUtils.js";
import { ACTION, pluckAction } from "./chatActions.js";
import { CHAT_EVENT } from "./events.js";
import LoadingSpinner from "./icons/LoadingSpinner.vue";
import ScheduleFollowUp from "./ScheduleFollowUp.vue";

const TWO_MINUTES = 120000;

export default {
    name: "ExitIntentDialog",
    components: {
        ScheduleFollowUp,
        LoadingSpinner,
    },
    inject: ["coachingSessionId", "user_details"],
    props: {
        messages: {
            type: Array,
            default: () => [],
        },
        hasFutureCalendarEvents: {
            type: Boolean,
            default: () => false,
        },
    },
    data() {
        return {
            date: nextWorkday(roundToNearest15(DateTime.now().plus({ days: 3 }))).toJSDate(),
            localRecurring: this.user_details.prefers_weekly_check_in,
            loading: false,
            submitLoading: false,
            submitError: null,
            generatedCopy: null,
        };
    },
    computed: {
        inputDate() {
            if (this.followUpAction) {
                const dateString = this.followUpAction.line.action_params.event_at_confirmed ?? this.followUpAction.line.action_params.event_at_guessed;
                return new Date(dateString);
            }

            return this.date;
        },
        recurring() {
            if (this.followUpAction) {
                return this.followUpAction.line.action_params.recurring ?? true;
            }

            return this.localRecurring;
        },
        copy() {
            if (this.generatedCopy) {
                return this.generatedCopy;
            }

            if (this.followUpAction && this.followUpAction.line.action_params.invite_description) {
                return this.followUpAction.line.action_params.invite_description;
            }

            return "Let's schedule some time to continue our conversation.";
        },
        followUpAction() {
            return pluckAction(this.messages, ACTION.FOLLOW_UP, true);
        },
    },
    mounted() {
        this.emitter.on(CHAT_EVENT.OPEN_EXIT_INTENT_DIALOG, this.open);
        document.addEventListener("onboarding_followup", this.open);
    },
    unmounted() {
        this.emitter.off(CHAT_EVENT.OPEN_EXIT_INTENT_DIALOG, this.open);
        document.removeEventListener("onboarding_followup", this.open);
    },
    methods: {
        async schedule() {
            /* If the follow-up action exists in the current chat, the follow-up
             * is scheduled through the action's handler so that the widget
             * state is synchronized. Otherwise, the follow-up is scheduled
             * ad-hoc.
             */
            try {
                this.submitError = null;
                this.submitLoading = true;
                if (this.followUpAction) {
                    const confirmedTime = this.followUpAction.line.action_params.event_at_confirmed ?? this.followUpAction.line.action_params.event_at_guessed;

                    const action_data = {
                        message_id: this.followUpAction.message.chat_message_id,
                        lineIdx: this.followUpAction.lineIdx,
                        action_params: {
                            calendar_event_id: this.followUpAction.line.action_params.calendar_event_id,
                            invite_description: this.copy,
                            event_at_confirmed: confirmedTime,
                            recurring: this.localRecurring,
                        },
                        action_state: {
                            submitted: true,
                        },
                    };
                    this.emitter.emit("choose_followup", action_data);
                } else {
                    await this.$sendEvent("schedule_follow_up", {
                        event_description: this.copy,
                        event_at_confirmed: DateTime.fromJSDate(this.date).toISO(),
                        recurring: this.recurring,
                    });
                }
                this.emitter.emit("onboarding_followup_scheduled");
                logUserInteraction("exit_intent_followup_scheduled", {}, this.coachingSessionId);
                this.dismiss();
            } catch (e) {
                this.submitError = e;
                if ("Sentry" in window) {
                    window.Sentry.captureException(e);
                }
            } finally {
                this.submitLoading = false;
            }
        },
        onDateUpdate(newDate) {
            this.date = newDate;
        },
        onRecurringUpdate() {
            this.localRecurring = !this.localRecurring;
        },
        handleExitIntentMouseover() {
            const exitIntentSessionStorage = window.sessionStorage.getItem("exit-intent");

            if (exitIntentSessionStorage !== null) {
                return;
            }

            const userMessages = this.messages.filter((m) => m.role === "user" && !m.hidden);

            if (userMessages.length === 0 || new Date() - new Date(userMessages.pop().sentAt) < TWO_MINUTES) {
                return;
            }

            if (this.followUpAction && this.followUpAction.line.action_state.submitted) {
                return;
            }

            // Do not show the exit intent modal if the user has any future calendar events already
            if (this.hasFutureCalendarEvents) {
                return;
            }

            this.open();
        },
        cancel() {
            this.dismiss();
        },
        open() {
            this.generateCopy();
            logUserInteraction("exit_intent_followup_viewed", {}, this.coachingSessionId);
            this.$refs.dialogElement.showModal();
        },
        dismiss() {
            this.submitError = null;
            window.sessionStorage.setItem("exit-intent", JSON.stringify({ seen: true }));
            this.$refs.dialogElement.close();
        },
        async generateCopy() {
            this.loading = true;
            try {
                const response = await this.$sendEvent("generate_exit_follow_up_copy");
                this.generatedCopy = response.payload;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped>
dialog#exit-intent-dialog::backdrop {
    background-color: #000;
    opacity: 0.4;
}
</style>
