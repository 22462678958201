import { inject } from "vue";

/*
 * Composable to send an action event to invoke an action handler.
 * The chat message action state in `ChatDetail` is updated when the
 * action is resolved.
 *
 * If `sync` is passed to the `execute` handler, the action handler
 * is running synchronously so that the action handler payload
 * or errors are returned to the caller.
 */
export function useActionHandler() {
    const { $sendEvent } = inject("globalProperties");
    const updateActionState = inject("updateActionState");

    async function execute(actionType, payload, { sync = false } = {}) {
        const result = await $sendEvent("action", { data: { type: actionType, payload, sync } });
        updateActionState(payload);
        return result;
    }

    return {
        execute,
    };
}
