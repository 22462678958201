<template>
    <div class="flex flex-col gap-3 items-start mt-4">
        <ChatLoadingFrame :is-loading="!tool.completed">
            {{ label }}
        </ChatLoadingFrame>
        <Transition name="fade">
            <component :is="toolComponent" v-if="tool.completed" v-bind="tool" />
        </Transition>
    </div>
</template>

<script setup>
import { ACTION } from "~vue/chatActions.js";
import ChatAgentToolActionItems from "~vue/ChatDetail/ChatAgentToolActionItems.vue";
import ChatAgentToolEmailChatSummary from "~vue/ChatDetail/ChatAgentToolEmailChatSummary.vue";
import ChatAgentToolFollowup from "~vue/ChatDetail/ChatAgentToolFollowup.vue";
import ChatLoadingFrame from "~vue/ChatLoadingFrame.vue";
import { computed } from "vue";

const props = defineProps({
    tool: {
        type: Object,
        required: true,
        default: () => ({}),
    },
});

const toolComponent = computed(() => {
    switch (props.tool.name) {
        case ACTION.FOLLOW_UP:
            return ChatAgentToolFollowup;
        case ACTION.EMAIL_CHAT_SUMMARY:
            return ChatAgentToolEmailChatSummary;
        case ACTION.ACTION_ITEMS:
            return ChatAgentToolActionItems;
    }

    return null;
});

const label = computed(() => {
    switch (props.tool.name) {
        case ACTION.FOLLOW_UP:
            return "Generating your invite";

        case ACTION.EMAIL_CHAT_SUMMARY:
            return "Generating a summary";

        case ACTION.ACTION_ITEMS:
            return "Generating your action item";

        default:
            return "Running...";
    }
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 300ms ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

i.bi::before {
    font-weight: bold !important;
}
</style>
